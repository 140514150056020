import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';

const RoundInfoTitle = styled.div<{ color: 'white' | 'blue' }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 141px;
  height: 32px;
  padding: 0 9px;

  border-radius: 20px;
  background-color: ${({ color }) =>
    color === 'white' ? theme.colors.white : theme.colors.blue[200]};

  font-family: 'Gowun Dodum';
  font-size: 15px;
  color: ${theme.colors.black};
`;

const RoundInfoTitleDot = styled.div`
  width: 3px;
  height: 3px;

  border-radius: 50%;
  background-color: ${theme.colors.black};
`;

interface RoundedInfoTitleProps {
  color: 'white' | 'blue';
  children: React.ReactNode;
}

export const RoundedInfoTitle: FC<RoundedInfoTitleProps> = ({
  color = 'white',
  children,
}) => {
  return (
    <RoundInfoTitle color={color}>
      <RoundInfoTitleDot />
      {children}
      <RoundInfoTitleDot />
    </RoundInfoTitle>
  );
};
