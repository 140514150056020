import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';

const BaseButton = styled.button`
  width: 250px;
  height: 60px;

  border-radius: 5px;

  font-family: 'Gowun Dodum';
  font-size: 24px;
  line-height: 150%;
`;

const WhiteButton = styled(BaseButton)`
  background-color: ${theme.colors.orange[10]};
  color: ${theme.colors.blue[400]};
`;

const BorderedButton = styled(BaseButton)`
  border: 1px solid ${theme.colors.black};

  background-color: transparent;
  color: ${theme.colors.black};
`;

interface ButtonProps {
  color: 'white' | 'transparent';
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  color,
  className,
  onClick,
  children,
}) => {
  const handleOnClick = () => {
    onClick && onClick();
  };

  const StyledButton = {
    white: (
      <WhiteButton type="button" className={className} onClick={handleOnClick}>
        {children}
      </WhiteButton>
    ),
    transparent: (
      <BorderedButton
        type="button"
        className={className}
        onClick={handleOnClick}
      >
        {children}
      </BorderedButton>
    ),
  };

  return StyledButton[color];
};
