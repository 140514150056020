export const FAQ_LIST = [
  {
    id: 'faq01',
    question: '기존 구글 워크스페이스를 쓰는 기업도 참여 가능한가요?',
    answer:
      '기존에 구글 워크스페이스를 쓰고 있더라도 고위드로 계약 이전을 할 수 있습니다. 다만 다른 리셀러 등과 연계약을 한 경우에는 계약 만료 시점에 맞춰 계약 이전 알림을 보내드릴 수 있어요. 우선 신청 후 상담을 받아보세요 :) 아직 구글 워크스페이스를 쓰지 않는 신규 고객의 경우 회사 규모에 따라 더 높은 할인율을 제공하고 있습니다.',
  },
  {
    id: 'faq02',
    question: '기금 적립을 위해 별도 비용이 발생하나요?',
    answer:
      '고위드는 스타트업들이 더 많이 모이고, 함께했을 때 발생하는 고위드의 수익 대부분을 기금 적립에 활용하게 됩니다. 스타트업은 업계 최고 수준의 할인 혜택을 받으시면서도, 별도 비용 없이 기금 적립에 참여하실 수 있습니다.',
  },
  {
    id: 'faq03',
    question: '무이자 대출 수혜 스타트업 선정 과정은 어떻게 되나요?',
    answer: `얼어붙은 투자환경 등으로 인해 최근 스타트업에게 혹한기가 찾아왔습니다. 다만 어려운 상황 속에서도 뼈아픈 구조조정과 사업 축소를 통해 이러한 상황을 이겨내고자 하는 스타트업도 많습니다. 고위드는 이렇게 고비를 넘기며 힘을 내보려는 스타트업에게 '무이자 대출'이라는 '마중물'을 제공하여, 다시 성장할 수 있는 발판을 마련해드리고자 합니다. 자세한 선정 과정과 선정 기업은 정기적으로 참여사들에게 공유해드릴 예정입니다.`,
  },
  {
    id: 'faq04',
    question: '무이자 대출 지원 방식은 어떻게 되나요?',
    answer:
      '최대 약 2억원 정도의 금액을 선정된 스타트업에게 무이자대출 형태로 지원하게 됩니다. 합의된 성장 지표 또는 투자 유치 등을 달성했을 때 해당 원금을 돌려받고, 선택적으로 해당 기금에 추가 기부를 하는 방향으로 진행될 예정입니다.',
  },
];
