import React, { FC } from 'react';
import styled from 'styled-components';
import { GowidLogo } from '../../assets/images';
import theme from '../../styles/theme';

const FooterWrap = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 32px 26px;

  background-color: ${theme.colors.white};
`;

const GowidLogoImage = styled.img`
  width: 117px;
`;

const FooterTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FooterText = styled.p`
  font-size: 13px;
  line-height: 150%;
  color: ${theme.colors.gray[600]};
`;

const Divider = styled.div`
  display: inline-flex;
  vertical-align: middle;

  width: 1px;
  height: 13px;

  margin: 0 8px 2px 8px;

  background-color: ${theme.colors.gray[300]};
`;

const Footer: FC = () => {
  return (
    <FooterWrap>
      <GowidLogoImage alt="고위드 로고" src={GowidLogo} />
      <FooterTextWrap>
        <FooterText>
          주식회사 고위드 <Divider /> 대표이사 : 김항기
        </FooterText>
        <FooterText>사업자등록번호 : 261-81-25793</FooterText>
        <FooterText>통신판매업신고 : 제 2020-서울강남-01863호</FooterText>
        <FooterText>
          서울특별시 강남구 도산대로 317, 14층 <br /> (신사동, 호림아트센터
          1빌딩)
        </FooterText>
        <FooterText>
          대표전화 : 02-6105-8101 <Divider /> 이메일 : support@gowid.com
        </FooterText>
      </FooterTextWrap>
    </FooterWrap>
  );
};

export default Footer;
