import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './main/Main';
import MobileMain from './mobile/MobileMain';
import renderByDevice from './utils/renderByDevice';
import Redirect from './common/pages/Redirect';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={renderByDevice.isMobile() ? <MobileMain /> : <Main />}
        />
        <Route path="/redirect" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  );
}
