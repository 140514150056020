import { DefaultTheme } from 'styled-components';

const BLUE = {
  200: '#A8CAFF',
  400: '#5D91FF',
} as const;

const GRAY = {
  10: '#FCFCFD',
  300: '#DBE0E5',
  500: '#9FA8B1',
  600: '#848D96',
  700: '#566069',
  800: '#333940',
} as const;

const ORANGE = {
  10: '#FFFCF6',
  50: '#FFF9EC',
  100: '#FFEFCF',
  200: '#F8DCA5',
} as const;

const colors = {
  black: '#212529',
  blue: BLUE,
  gray: GRAY,
  orange: ORANGE,
  white: '#FFFFFF',
} as const;

export type ColorsTypes = typeof colors;

const theme: DefaultTheme = {
  colors,
};

export default theme;
