import React, { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import * as theme from '../styles/theme';
import {
  Character,
  FaceBookIcon,
  GowidLogo,
  LinkedInIcon,
  Logo,
  UnderArrow,
  ChequerLogo,
  YoutubeIcon,
  GHuddlingProcessDesktop,
  NewsThumbnail,
  NriseLogo,
  MystericoLogo,
} from '../assets/images';
import '../fonts/font.css';
import { EXTERNAL_LINK_URL } from '../common/constants/main.const';
import { RoundedInfoTitle } from './components/RoundedInfoTitle';
import Modal from '../common/components/Modal';

const MainWrap = styled.main`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${theme.default.colors.orange[50]};
`;

const MainHeader = styled.header`
  width: 100%;
  height: 80px;
  overflow: hidden;

  background-color: ${theme.default.colors.orange[50]};
`;

const MainLogoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  max-width: 1440px;
  height: 80px;

  padding-left: 121px;
  margin: 0 auto;
`;

const MainLogo = styled.img`
  width: 115px;
  height: 33px;
`;

const SectionWarp = styled.div<{ height?: string; bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: ${({ height }) => (height ? `${height}` : 'auto')};
  overflow: hidden;

  ${({ bgColor }) => `background-color: ${bgColor}`};
`;

const CampaignContent = styled.section`
  display: flex;
  flex-direction: column;

  max-width: 1440px;
  width: 1440px;
  padding: 79px 121px 68px;
  overflow: hidden;
`;

const Section = styled.div<{
  height?: string;
  bgColor?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: '100%';
  height: ${({ height }) => (height ? `${height}` : 'auto')};

  ${({ bgColor }) => `background-color: ${bgColor}`};
`;

const MainSectionTitleWrap = styled.div`
  display: flex;
  align-items: center;

  gap: 100px;
`;

const MainSectionTitleTextWrap = styled.div`
  display: flex;

  flex-direction: column;

  width: 60%;
`;

const HashTagWrap = styled.div`
  margin-top: 51px;

  display: flex;

  flex-direction: row;
  justify-content: center;

  gap: 40px;
`;

const HashTag = styled.p`
  font-size: 20px;
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  line-height: 180%;

  color: ${theme.default.colors.orange[100]};
`;

const SubTitle = styled.p`
  font-size: 48px;
  font-family: 'Gowun Dodum';
  font-weight: 400;
  line-height: 180%;

  color: ${theme.default.colors.orange[100]};
`;

const Title = styled.p`
  margin-top: 21px;

  font-size: 98px;
  font-family: 'Gowun Dodum';
  font-weight: 400;
  line-height: 92px;

  color: ${theme.default.colors.orange[50]};
`;

const CampaignDescription = styled.div`
  margin-top: 65px;

  font-size: 20px;
  font-weight: 500;
  line-height: 170%;

  text-align: left;

  color: ${theme.default.colors.orange[100]};
`;

const CharacterImageWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 40%;
  height: 100%;
`;

const CharacterImage = styled.img`
  width: 421px;
  height: 410px;

  margin-top: 21px;
`;

const CampaignButton = styled.button<{
  color?: string;
  bgColor?: string;
  marginTop?: string;
}>`
  width: 255px;
  height: 60px;

  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : '16px')};

  ${({ bgColor }) => `background-color: ${bgColor}`};
  ${({ color }) => `color: ${color}`};

  font-size: 23px;
  font-family: 'Gowun Dodum';
  font-weight: 400;

  border-radius: 5px;
  border-width: 0px;
`;

const GHuddlingProcessImage = styled.img`
  width: 1198px;
  height: 476px;

  margin-top: 74px;
`;

const MarketingContent = styled.section`
  display: flex;
  flex-direction: row;

  max-width: 1440px;
  width: 1440px;
  padding: 78px 69px 153px 92px;
  overflow: hidden;

  gap: 53px;
`;

const NewsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 50%;
`;

const NewsTitle = styled.p`
  font-family: 'Gowun Dodum';
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 75px;
  text-align: left;

  color: ${theme.default.colors.gray[10]};

  width: 100%;
`;

const NewsContentWrap = styled.div`
  display: flex;
  flex-direction: row;

  gap: 18px;
`;

const NewsImage = styled.img`
  width: 215px;
  height: 235px;

  margin-top: 36px;
`;

const NewsDescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const NewsDescription = styled.p`
  margin-top: 24px;

  font-family: 'Gowun Dodum';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 150%;

  color: ${theme.default.colors.white};
`;

const YoutubeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;

  gap: 10px;
`;

const MarketingButton = styled.button<{
  color?: string;
  bgColor?: string;
  marginTop?: string;
}>`
  width: 250px;
  height: 60px;

  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : '16px')};

  ${({ bgColor }) => `background-color: ${bgColor}`};
  ${({ color }) => `color: ${color}`};

  font-size: 23px;
  font-family: 'Gowun Dodum';
  font-weight: 400;
  line-height: 33px;

  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: white;
`;

const InfoWrap = styled.div<{
  marginTop?: string;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 37px;
  flex-basis: 300px;
`;

const CorpAmountAndCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

const CorpInfoContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 1440px;
  max-width: 1440px;
  padding: 95px 203px 102px 193px;
`;

const RoundSectionDescription = styled.div`
  margin: 0 auto;

  font-size: 33px;
  font-family: 'Gowun Dodum';
  font-weight: 500;
  line-height: 48px;

  color: ${theme.default.colors.black};
`;

const ParticipantsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  width: 100%;
  margin-top: 74px;
`;

const JoinCorpWrap = styled.div`
  margin-top: 18px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  gap: 100px;
`;

const TempJoinCorpLogo = styled.div<{
  color?: string;
  bgColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 240px;
  height: 60px;

  ${({ bgColor }) => `background-color: ${bgColor}`};
  ${({ color }) => `color: ${color}`};

  border-style: solid;
  border-radius: 20px;
  border-width: 1px;
  border-color: #ebb03b;

  gap: 10px;
`;

const TempJoinCorpText = styled.p<{
  color?: string;
}>`
  ${({ color }) => `color: ${color}`};

  font-size: 23px;
  font-family: 'Gowun Dodum';
  font-weight: 400;
  line-height: 33px;
`;

const MoreHuddlingInfoDescription = styled.p`
  margin-top: 73px;

  padding: 0 23px;

  font-family: 'Gowun Dodum';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 180%;

  color: ${theme.default.colors.black};
`;

const FAQWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  padding: 40px 120px;

  width: 1440px;
  max-width: 1440px;
`;

const FAQTitleWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-bottom: 24px;
  padding-bottom: ${({ isOpen }) => (isOpen ? `0` : `24px`)};

  border: 1px solid ${theme.default.colors.black};
  border-bottom: ${({ isOpen }) => (isOpen ? `0` : `1`)};
  border-top: 0;
  border-right: 0;
  border-left: 0;
`;

const FAQTitle = styled.p`
  font-family: 'Gowun Dodum';
  font-weight: 700;
  font-size: 17px;
  line-height: 180%;

  text-align: left;

  color: ${theme.default.colors.black};
`;

const FAQButton = styled.button`
  width: 100%;
`;

const FAQArrow = styled.img<{ isOpen: boolean }>`
  width: 25px;
  height: 12px;

  transition: all ease-in-out 0.2s;

  ${({ isOpen }) =>
    isOpen && `transform: rotate(180deg); transition: all ease-in-out 0.2s`};
`;

const FAQContentWrap = styled.div<{ isOpen: boolean }>`
  visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
  height: ${({ isOpen }) => (isOpen ? `auto` : `0`)};
  opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};

  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;

  margin-bottom: ${({ isOpen }) => (isOpen ? `24px` : `0`)};

  transition: all ease-in-out 0.2s;

  padding-bottom: ${({ isOpen }) => (isOpen ? `24px` : `0`)};

  border: 1px solid ${theme.default.colors.black};
  border-bottom: ${({ isOpen }) => (isOpen ? `1` : `0`)};
  border-top: 0;
  border-right: 0;
  border-left: 0;
`;

const FAQContent = styled.p`
  font-size: 14.5px;
  line-height: 1.8;
  color: ${theme.default.colors.gray[800]};
`;

const SNSWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding: 63px 0;
`;

const SNSTitle = styled.p`
  font-family: 'Gowun Dodum';
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;

  text-align: center;

  color: ${theme.default.colors.black};
`;

const SNSIconWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  gap: 20px;

  margin-top: 30px;
`;

const SNSButton = styled.button``;

const SNSIconImage = styled.img`
  width: 80px;
  height: 80px;
`;

const FooterWarp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  width: 1440px;
  max-width: 1440px;

  padding-top: 73px;
  padding-bottom: 44px;

  border: 1px solid ${theme.default.colors.black};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
`;

const GowidLogoImage = styled.img`
  width: 117px;
  height: 44px;
`;

const FooterContent = styled.div`
  margin-top: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 10px;

  font-size: 13px;
  line-height: 1.4;
  color: ${theme.default.colors.gray[600]};
`;

const SendEmailButton = styled.button`
  color: ${theme.default.colors.gray[600]};
  font-size: 13px;
  line-height: 1.4;
`;

const Main: FC = () => {
  const initArray = Array.from({ length: 8 }, _ => false);
  const [openFAQ, setOpenFAQ] = useState<boolean[]>([...initArray]);

  const handleOnClickParticipateCampaign: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    window.open(EXTERNAL_LINK_URL.google_form);
  };

  const handleOnClickNews: MouseEventHandler<HTMLButtonElement> = () => {
    window.open(EXTERNAL_LINK_URL.news);
  };

  const handleOnClickFAQButton: MouseEventHandler<HTMLButtonElement> = e => {
    const { id } = e.currentTarget;
    const index = Number(id);
    const copiedArray = openFAQ;
    copiedArray[index] = !openFAQ[index];

    setOpenFAQ([...copiedArray]);
  };

  const handleOnClickMoveFaceBookButton: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    window.open(EXTERNAL_LINK_URL.facebook);
  };

  const handleOnClickMoveLinkedInButton: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    window.open(EXTERNAL_LINK_URL.linkedin);
  };

  const handleOnClickMoveYoutubeButton: MouseEventHandler<
    HTMLButtonElement
  > = () => {
    window.open(EXTERNAL_LINK_URL.youtube);
  };

  const handleOnClickMailto: MouseEventHandler<HTMLButtonElement> = () => {
    window.location.href = 'mailto:support@gowid.com';
  };

  return (
    <>
      <Modal />
      <MainWrap>
        <MainHeader>
          <MainLogoWrap>
            <MainLogo src={Logo} />
          </MainLogoWrap>
        </MainHeader>
        <SectionWarp bgColor={theme.default.colors.blue[400]}>
          <CampaignContent>
            <MainSectionTitleWrap>
              <MainSectionTitleTextWrap>
                <SubTitle>투자 빙하기를 녹이는 온기</SubTitle>
                <Title>G허들링</Title>

                <CampaignDescription>
                  고위드를 통해 구글 워크스페이스(구.G-Suite)를 결제하시고,
                  <br />
                  결제금액의 10%를 스타트업을 위한 무이자 대출 기금에
                  적립하세요.
                </CampaignDescription>
                <HashTagWrap>
                  <HashTag>#구글 워크스페이스(GWS) 업계 최대 할인률</HashTag>
                  <HashTag>#별도 비용 없이 스타트업 돕기</HashTag>
                </HashTagWrap>

                <CampaignButton
                  color={theme.default.colors.blue[400]}
                  bgColor={theme.default.colors.gray[10]}
                  marginTop="24px"
                  onClick={handleOnClickParticipateCampaign}
                >
                  허들링 참여하기
                </CampaignButton>
              </MainSectionTitleTextWrap>

              <CharacterImageWrap>
                <CharacterImage src={Character} />
              </CharacterImageWrap>
            </MainSectionTitleWrap>

            <Section>
              <GHuddlingProcessImage src={GHuddlingProcessDesktop} />
            </Section>
          </CampaignContent>
        </SectionWarp>
        <SectionWarp height="100%" bgColor={theme.default.colors.black}>
          <MarketingContent>
            <NewsWrap>
              <NewsTitle>G허들링 보도자료</NewsTitle>
              <NewsContentWrap>
                <NewsImage src={NewsThumbnail} />
                <NewsDescriptionWrap>
                  <NewsDescription>
                    조선닷컴 [쫌아는기자들]
                    <br />
                    G허들링, “구조조정한 어려운
                    <br /> 스타트업에게 무이자로
                    <br /> 2억원 빌려주겠다.”
                  </NewsDescription>
                  <MarketingButton
                    color={theme.default.colors.white}
                    bgColor={theme.default.colors.black}
                    marginTop="0px"
                    onClick={handleOnClickNews}
                  >
                    기사 보러가기
                  </MarketingButton>
                </NewsDescriptionWrap>
              </NewsContentWrap>
            </NewsWrap>
            <YoutubeWrap>
              <NewsTitle>G허들링 소개 영상</NewsTitle>
              <iframe
                width="630"
                height="276"
                src="https://www.youtube.com/embed/uySQa9Ldo4I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </YoutubeWrap>
          </MarketingContent>
        </SectionWarp>

        <SectionWarp height="100%" bgColor={theme.default.colors.orange[10]}>
          <CorpInfoContent>
            <CorpAmountAndCountWrap>
              <InfoWrap>
                <RoundedInfoTitle color="blue">총 누적 모금액</RoundedInfoTitle>
                <RoundSectionDescription>10,000,000원</RoundSectionDescription>
              </InfoWrap>
              <InfoWrap>
                <RoundedInfoTitle color="blue">매월</RoundedInfoTitle>
                <RoundSectionDescription>
                  144,549원 적립 중
                </RoundSectionDescription>
              </InfoWrap>
              <InfoWrap>
                <RoundedInfoTitle color="blue">참여 법인 수</RoundedInfoTitle>
                <RoundSectionDescription>7곳</RoundSectionDescription>
              </InfoWrap>
            </CorpAmountAndCountWrap>
            <ParticipantsWrap>
              <RoundedInfoTitle color="blue">참여 법인</RoundedInfoTitle>
              <JoinCorpWrap>
                <img
                  width={120}
                  height={44}
                  src={GowidLogo}
                  alt={'고위드 로고'}
                />
                <img
                  width={97}
                  height={66}
                  src={ChequerLogo}
                  alt={'체커 로고'}
                />
                <img
                  width={125}
                  height={32}
                  src={NriseLogo}
                  alt={'엔라이즈 로고'}
                />
                <img
                  width={108}
                  height={74}
                  src={MystericoLogo}
                  alt={'미스테리코 로고'}
                />
              </JoinCorpWrap>
              <JoinCorpWrap>
                <TempJoinCorpLogo color={theme.default.colors.black}>
                  <TempJoinCorpText>온오프믹스</TempJoinCorpText>
                </TempJoinCorpLogo>
                <TempJoinCorpLogo color={theme.default.colors.black}>
                  <TempJoinCorpText>모두싸인</TempJoinCorpText>
                  <TempJoinCorpText color={theme.default.colors.gray[500]}>
                    (계약 중)
                  </TempJoinCorpText>
                </TempJoinCorpLogo>

                <TempJoinCorpLogo color={theme.default.colors.black}>
                  <TempJoinCorpText>수호아이오</TempJoinCorpText>
                  <TempJoinCorpText color={theme.default.colors.gray[500]}>
                    (계약 중)
                  </TempJoinCorpText>
                </TempJoinCorpLogo>
              </JoinCorpWrap>
            </ParticipantsWrap>
          </CorpInfoContent>
        </SectionWarp>
        <SectionWarp bgColor="#F5F5F5">
          <Section>
            <MoreHuddlingInfoDescription>
              G허들링에 대해 더 자세히 알아보세요.
            </MoreHuddlingInfoDescription>
          </Section>

          <Section height="68px" />
          <FAQWrap>
            <FAQButton id="0" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[0]}>
                <FAQTitle>
                  Q. 고위드는 어떻게 높은 구글 워크스페이스 할인 혜택을 제공할
                  수 있게 되었나요?
                </FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[0]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[0]}>
              <FAQContent>
                구글 본사 측에서 고위드는 국내 스타트업에 대한 이해도가 높고,
                성장하는 스타트업이 많이 모여 있는 곳이라고 인식하고 있습니다.
                <br />
                덕분에, 저희는 스타트업 대상으로 좋은 가격 조건을 제공할 수 있게
                되었고, 그 혜택을 최대한 스타트업에게 모두 전달되도록 노력하고
                있습니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="1" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[1]}>
                <FAQTitle>
                  Q. 기존 구글 워크스페이스를 쓰는 기업도 참여 가능한가요?
                </FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[1]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[1]}>
              <FAQContent>
                기존에 구글 워크스페이스를 쓰고 있더라도 고위드로 계약 이전을 할
                수 있습니다. <br />
                다만 다른 리셀러 등과 연계약을 한 경우에는 계약 만료 시점에 맞춰
                계약 이전 알림을 보내드릴 수 있어요.
                <br /> 우선 신청 후 상담을 받아보세요 :) <br />
                아직 구글 워크스페이스 쓰지 않는 신규 고객의 경우 회사 규모에
                따라 더 높은 할인율 제공하고 있습니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="2" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[2]}>
                <FAQTitle>Q. 계약 진행은 어떻게 되나요?</FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[2]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[2]}>
              <FAQContent>
                모두싸인으로 온라인 리셀러 계약을 진행한 후, 고위드 카드를 최초
                한번만 등록해주시면 매월 할인된 월 요금이 자동으로 결제됩니다.
                <br />
                고위드 카드를 발급 받지 않고 세금계산서를 통해 결제하는 방법도
                있습니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="3" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[3]}>
                <FAQTitle>Q. 기금 적립을 위해 별도 비용이 발생하나요?</FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[3]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[3]}>
              <FAQContent>
                고위드는 스타트업들이 더 많이 모이고, 함께했을 때 발생하는
                고위드의 수익 대부분을 기금 적립에 활용하게 됩니다.
                <br /> 스타트업은 업계 최고 수준의 할인 혜택을 받으시면서도,
                별도 비용 없이 기금 적립에 참여하실 수 있습니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="4" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[4]}>
                <FAQTitle>
                  Q. 무이자 대출 수혜 스타트업 선정 과정은 어떻게 되나요?
                </FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[4]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[4]}>
              <FAQContent>
                얼어붙은 투자환경 등으로 인해 최근 스타트업에게 혹한기가
                찾아왔습니다.
                <br /> 다만 어려운 상황 속에서도 뼈아픈 구조조정과 사업 축소를
                통해 이러한 상황을 이겨내고자 하는 스타트업도 많습니다.
                <br /> 고위드는 이렇게 고비를 넘기며 힘을 내보려는 스타트업에게
                ‘무이자 대출’이라는 ‘마중물’을 제공하여, 다시 성장할 수 있는
                발판을 마련해드리고자 합니다.
                <br /> 자세한 선정 과정과 선정 기업은 정기적으로 참여사들에게
                공유해드릴 예정입니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="5" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[5]}>
                <FAQTitle>Q. 무이자 대출 지원 방식은 어떻게 되나요?</FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[5]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[5]}>
              <FAQContent>
                최대 약 2억원 정도의 금액을 선정된 스타트업에게 무이자대출
                형태로 지원하게 됩니다. <br />
                합의된 성장 지표 또는 투자 유치 등을 달성했을 때 해당 원금을
                돌려받고, 선택적으로 해당 기금에 추가 기부를 하는 방향으로
                진행될 예정입니다.
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="6" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[6]}>
                <FAQTitle>
                  Q. GWS 결제 외 다른 방식으로도 참여할 수 있나요?
                </FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[6]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[6]}>
              <FAQContent>
                현재 비영리법인 설립 단계에 있기 때문에 당장은 구글워크스페이스
                결제 외에 기부할 수 있는 방법은 없습니다.
                <br />
                다만 홍보를 지원해주고 싶으시거나, 추후 기부 의사가 있으신 경우
                참여 신청서를 작성해주세요!
              </FAQContent>
            </FAQContentWrap>
            <FAQButton id="7" type="button" onClick={handleOnClickFAQButton}>
              <FAQTitleWrap isOpen={openFAQ[7]}>
                <FAQTitle>
                  Q. 이미 고위드를 통해 GWS를 결제하고 있어도 참여할 수 있나요?
                </FAQTitle>
                <FAQArrow
                  src={UnderArrow}
                  alt="자주 묻는 질문 펼치기 화살표"
                  isOpen={openFAQ[7]}
                />
              </FAQTitleWrap>
            </FAQButton>
            <FAQContentWrap isOpen={openFAQ[7]}>
              <FAQContent>
                당연히 참여가 가능합니다. <br />
                다만 기존 고객의 경우 GWS 결제액의 최대 3%가 적립되는
                구조입니다.
              </FAQContent>
            </FAQContentWrap>
          </FAQWrap>
          <SNSWrap>
            <SNSTitle>모두 함께 모여 온기를 전해요</SNSTitle>
            <MainLogo src={Logo} />
            <Section height="115px">
              <CampaignButton
                color={theme.default.colors.orange[50]}
                bgColor={theme.default.colors.blue[400]}
                marginTop="45px"
                onClick={handleOnClickParticipateCampaign}
              >
                허들링 참여하기
              </CampaignButton>
            </Section>
            <SNSIconWrap>
              <SNSButton
                type="button"
                onClick={handleOnClickMoveFaceBookButton}
              >
                <SNSIconImage
                  src={FaceBookIcon}
                  alt="페이스북으로 이동하기 아이콘"
                />
              </SNSButton>

              <SNSButton
                type="button"
                onClick={handleOnClickMoveLinkedInButton}
              >
                <SNSIconImage
                  src={LinkedInIcon}
                  alt="링크드인으로 이동하기 아이콘"
                />
              </SNSButton>

              <SNSButton type="button" onClick={handleOnClickMoveYoutubeButton}>
                <SNSIconImage
                  src={YoutubeIcon}
                  alt="유튜브로 이동하기 아이콘"
                />
              </SNSButton>
            </SNSIconWrap>
          </SNSWrap>
          <FooterWarp>
            <GowidLogoImage src={GowidLogo}></GowidLogoImage>
            <FooterContent>
              <p>주식회사 고위드 | 대표이사 : 김항기</p>
              <p>사업자 등록 번호 : 261-81-25793</p>
              <p>통신판매업 신고 : 제 2020-서울강남-01863호</p>
              <p>
                서울특별시 강남구 도산대로 317, 14층 <br />
                (신사동, 호림아트센터 1빌딩)
              </p>
              <p>
                대표번호 : 02 - 6105-8101 | 이메일 :{' '}
                <SendEmailButton onClick={handleOnClickMailto}>
                  support@gowid.com
                </SendEmailButton>
              </p>
            </FooterContent>
          </FooterWarp>
        </SectionWarp>
      </MainWrap>
    </>
  );
};

export default Main;
