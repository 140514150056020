import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_LINK_URL } from '../constants/main.const';

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 390px;
  height: auto;

  padding: 20px;

  border-radius: 8px;

  background-color: ${theme.colors.white};
`;

const ModalTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const ModalContent = styled.div``;

const ModalContentText = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  white-space: pre-line;
  color: ${theme.colors.gray[800]};
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 45px;

  padding: 12px;

  border-radius: 8px;

  background-color: ${theme.colors.blue[400]};

  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  color: ${theme.colors.white};
`;

const Modal: FC = () => {
  const navigate = useNavigate();

  const handleOnClickButton = () => {
    navigate('/redirect', { state: { url: EXTERNAL_LINK_URL.gowid } });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Background>
      <ModalWrap>
        <ModalTitle>알림</ModalTitle>
        <ModalContent>
          <ModalContentText>
            {`2023년 9월 8일부로,\n고위드와 여러 후원사들이 함께한 G허들링 기금 모집 운동이\n시즌 1을 종료하고 재정비의 시간을 갖는 중입니다.\nG허들링 시즌 2에서는\n지금보다 더 정비된 모습으로 다시 찾아 뵙겠습니다.`}
          </ModalContentText>
        </ModalContent>
        <ModalFooter>
          <ModalButton type="button" onClick={handleOnClickButton}>
            고위드 홈페이지로 이동
          </ModalButton>
        </ModalFooter>
      </ModalWrap>
    </Background>
  );
};

export default Modal;
