import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-size: 14px;
  }

  ol,
  ul {
    list-style: none;
  }

  button {
    font-family: 'Spoqa Han Sans Neo';
    background-color: transparent;
    outline: none;
    border: 0;
    color: inherit;
    
    &:hover {
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
  }
`;

export default GlobalStyle;
