import React, { FC, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import {
  Logo,
  MobileProcess,
  NewsThumbnail,
  UnderArrow,
} from '../assets/images';
import theme from '../styles/theme';
import { EXTERNAL_LINK_URL } from '../common/constants/main.const';
import { RoundedInfoTitle } from '../main/components/RoundedInfoTitle';
import { FAQ_LIST } from '../main/constants/mobile.const';
import { Button } from './components/Button';
import Footer from './components/Footer';
import Modal from '../common/components/Modal';

const MobileMainWrap = styled.div``;

const MobileMainHeader = styled.header`
  display: flex;
  align-items: center;

  width: 100%;
  height: 80px;
  padding-left: 30px;

  background-color: ${theme.colors.orange[50]};
`;

const LogoImage = styled.img`
  width: 115px;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 60px 30px;

  background-color: ${theme.colors.blue[400]};
  color: ${theme.colors.orange[100]};
`;

const MainSectionTitle = styled.h1`
  font-family: 'Gowun Dodum';
  font-size: 60px;
  line-height: 150%;

  color: ${theme.colors.orange[50]};
`;

const MainSectionSubTitle = styled.h2`
  margin-bottom: 20px;

  font-family: 'Gowun Dodum';
  font-size: 28px;
  line-height: 150%;
`;

const MainSectionDescription = styled.p`
  margin-top: 60px;
  margin-bottom: 36px;

  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
`;

const HashTagText = styled.p`
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 150%;
`;

const ProcessImage = styled.img`
  width: 100%;
  margin: 24px 0;
`;

const SubSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 87px;

  padding: 40px 30px;

  background-color: ${theme.colors.orange[10]};
`;

const SubSectionItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

const SubSectionTitle = styled.h3`
  font-family: 'Gowun Dodum';
  font-size: 40px;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const SubSectionDescription = styled.p`
  text-align: center;
  font-family: 'Gowun Dodum';
  font-size: 20px;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const ArticlePhoto = styled.img`
  width: 181px;
`;

const SubSectionInfoDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SubSectionInfoData = styled.p`
  font-family: 'Gowun Dodum';
  font-size: 33px;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const FAQSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;

  padding: 40px 30px;

  background-color: ${theme.colors.orange[10]};
`;

const FAQTitle = styled.p`
  font-family: 'Gowun Dodum';
  font-size: 16px;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const FAQWrap = styled.div`
  width: 100%;
`;

const FAQItemWrap = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 0 7px;
  overflow: hidden;

  border-top: 1px solid ${theme.colors.orange[200]};

  &:last-child {
    border-bottom: 1px solid ${theme.colors.orange[200]};
  }
`;

const QuestionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 43px;
`;

const QuestionText = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  color: ${theme.colors.black};
`;

const ArrowIconButton = styled.button`
  width: fit-content;
  height: fit-content;
`;

const ArrowIcon = styled.img<{ isOpen: boolean }>`
  width: 15px;

  ${({ isOpen }) =>
    isOpen && `transform: rotate(180deg); transition: all ease-in-out 0.2s`};
`;

const AnswerTextWrap = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  transition: all ease-in-out 0.2s;
  animation: ${({ isOpen }) => (isOpen ? `fade-in` : `fade-out`)} 0.4s;
  animation-fill-mode: forwards;

  padding-bottom: 11px;

  font-family: 'Gowun Dodum';
  font-size: 12px;
  line-height: 150%;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const initOpenFAQ = Array.from({ length: FAQ_LIST.length }, _ => false);

const MobileMain: FC = () => {
  const [openFAQ, setOpenFAQ] = useState<boolean[]>([...initOpenFAQ]);

  const handleOnClickOpenFAQ: MouseEventHandler<HTMLButtonElement> = e => {
    const { id } = e.currentTarget;

    const index = Number(id.split('q')[1]) - 1;
    const copiedOpenFAQ = openFAQ;
    copiedOpenFAQ[index] = ![...openFAQ][index];

    setOpenFAQ([...copiedOpenFAQ]);
  };

  const handleOnClickParticipateButton = () => {
    window.open(EXTERNAL_LINK_URL.google_form);
  };

  const handleOnClickReadArticleButton = () => {
    window.open(EXTERNAL_LINK_URL.news);
  };

  return (
    <>
      <Modal />
      <MobileMainWrap>
        <MobileMainHeader>
          <LogoImage alt="G허들링 로고" src={Logo} />
        </MobileMainHeader>
        <MainSection>
          <MainSectionSubTitle>투자 빙하기를 녹이는 온기</MainSectionSubTitle>
          <MainSectionTitle>G허들링</MainSectionTitle>
          <MainSectionDescription>
            고위드를 통해 구글 워크스페이스 <br />
            (구.G-Suite)를 결제하시고,
            <br />
            결제금액의 10%를 스타트업을 위한
            <br />
            무이자 대출 기금에 적립하세요.
          </MainSectionDescription>
          <HashTagText>
            #구글 워크스페이스(GWS) 업계 최대 할인율
            <br />
            #별도 비용 없이 스타트업 돕기
          </HashTagText>
          <ProcessImage alt="G허들링 참여과정" src={MobileProcess} />
          <Button color="white" onClick={handleOnClickParticipateButton}>
            허들링 참여하기
          </Button>
        </MainSection>
        <SubSection>
          <SubSectionItem>
            <SubSectionTitle>G허들링 보도자료</SubSectionTitle>
            <ArticlePhoto alt="고위드 CEO 김항기" src={NewsThumbnail} />
            <SubSectionDescription>
              조선닷컴[쫌아는기자들]
              <br />
              G허들링, "구조조정한 어려운
              <br />
              스타트업에게 무이자로
              <br />
              2억원 빌려주겠다."
            </SubSectionDescription>
            <Button
              color="transparent"
              onClick={handleOnClickReadArticleButton}
            >
              기사 보러가기
            </Button>
          </SubSectionItem>
          <SubSectionItem>
            <SubSectionTitle>G허들링 소개 영상</SubSectionTitle>
            <iframe
              width="330"
              height="186"
              src="https://www.youtube.com/embed/uySQa9Ldo4I"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </SubSectionItem>
          <SubSectionItem>
            <SubSectionInfoDataWrap>
              <RoundedInfoTitle color="white">총 누적 모금액</RoundedInfoTitle>
              <SubSectionInfoData>10,000,000원</SubSectionInfoData>
            </SubSectionInfoDataWrap>
            <SubSectionInfoDataWrap>
              <RoundedInfoTitle color="white">매월</RoundedInfoTitle>
              <SubSectionInfoData>144,549원 적립 중</SubSectionInfoData>
            </SubSectionInfoDataWrap>
            <SubSectionInfoDataWrap>
              <RoundedInfoTitle color="white">참여 법인 수</RoundedInfoTitle>
              <SubSectionInfoData>7곳</SubSectionInfoData>
            </SubSectionInfoDataWrap>
          </SubSectionItem>
        </SubSection>
        <FAQSection>
          <FAQTitle>자주 묻는 질문</FAQTitle>
          <FAQWrap>
            {FAQ_LIST.map((faq, idx) => {
              return (
                <FAQItemWrap key={faq.id}>
                  <QuestionWrap>
                    <QuestionText>{faq.question}</QuestionText>
                    <ArrowIconButton
                      id={faq.id}
                      type="button"
                      onClick={handleOnClickOpenFAQ}
                    >
                      <ArrowIcon
                        alt="화살표 버튼 이미지"
                        src={UnderArrow}
                        isOpen={openFAQ[idx]}
                      />
                    </ArrowIconButton>
                  </QuestionWrap>
                  <AnswerTextWrap isOpen={openFAQ[idx]}>
                    {faq.answer}
                  </AnswerTextWrap>
                </FAQItemWrap>
              );
            })}
          </FAQWrap>
        </FAQSection>
        <Footer />
      </MobileMainWrap>
    </>
  );
};

export default MobileMain;
